// import { registerLocaleData } from '@angular/common';
import '@angular/common/locales/global/de';

import { isPlatformBrowser } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  inject,
  LOCALE_ID,
  NgModule,
  PLATFORM_ID,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import {
  FirebaseApp,
  initializeApp,
  provideFirebaseApp,
} from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { FirestoreSettings, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DEFAULT_OPTIONS } from '@angular/material/bottom-sheet';
import { MAT_FAB_DEFAULT_OPTIONS } from '@angular/material/button';
import {
  MAT_RIPPLE_GLOBAL_OPTIONS,
  provideNativeDateAdapter,
} from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltipDefaultOptions,
} from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { RegisterService } from '@memberspot/admin/auth/feature/register';
import { HUBSPOT_TOKEN } from '@memberspot/admin/auth/hubspot';
import { STRIPE_API_KEY } from '@memberspot/admin/billing/data-access/billing';
import {
  COPECART_WEBOOK_URL,
  DIGISTORE_WEBOOK_URL,
  ELOPAGE_WEBHOOK_URL,
  ZOOM_WEBOOK_URL,
} from '@memberspot/admin/integration/data-access/integrations';
import {
  HUBSPOT_CLIENT_ID,
  ZOHO_CLIENT_ID,
} from '@memberspot/admin/integration/data-access/school-apps';
import { CUSTOM_PROPERTIES_PROVIDER } from '@memberspot/admin/school/feature/custom-user-property';
import { AuthQuery } from '@memberspot/admin/shared/data-access/auth';
import { AdminConfigService } from '@memberspot/admin/shared/data-access/config';
import { MemberAreaQuery } from '@memberspot/admin/shared/data-access/memberarea';
import { SchoolsQuery } from '@memberspot/admin/shared/data-access/schools';
import { CLIENT_URL } from '@memberspot/admin/shared/feature/school-url';
import {
  FONT_CDN,
  THUMBNAIL_SERVICE_URL,
} from '@memberspot/admin/shared/service/thumbnail-generator';
import { FrontendAuthFeatureLoginModule } from '@memberspot/frontend/auth/feature/login';
import {
  APPLE_LOGIN_PROVIDER,
  AZURE_AD_LOGIN_PROVIDER,
  GOOGLE_LOGIN_PROVIDER,
} from '@memberspot/frontend/auth/service';
import { BACKEND_URL } from '@memberspot/frontend/shared/data-access/common';
import {
  BUCKETS,
  CUSTOM_VIEW,
  SCROLL_TOP_DISTANCE,
} from '@memberspot/frontend/shared/feature/services';
import { CUSTOM_COLORS } from '@memberspot/frontend/shared/ui/color-picker';
import { provideFrontendSharedUiHotToastConfig } from '@memberspot/frontend/shared/ui/hot-toast';
import { SideMenuActiveStyleInjectionToken } from '@memberspot/frontend/shared/ui/sidemenu';
import { ToolsModule } from '@memberspot/frontend/shared/ui/tools';
import { provideUserTracking } from '@memberspot/frontend/shared/user-tracking';
import { FEATURE_TOGGLE_INJECTION_TOKEN } from '@memberspot/frontend/shared/util/feature-toggle';
import { FrontendSharedUtilIntercomModule } from '@memberspot/frontend/shared/util/intercom';
import { FrontendSharedUtilSentryModule } from '@memberspot/frontend/shared/util/sentry';
import {
  APP_VERSION,
  CLIENT_DASHBOARD_USER_SERVICE_TOKEN,
  EXTENDED_BETA,
  INTERNAL_BETA,
  IS_ADMIN,
  IS_DEV_SUPPORT,
  IS_READ_ADMIN,
  PROD,
  SCOLLED_PROVIDER,
} from '@memberspot/frontend/shared/util/tokens';
import { TranslocoRootModule } from '@memberspot/frontend/shared/util/translation';
import { FILE_CDN } from '@memberspot/frontend/shared/util/upload';
import {
  VersionModule,
  VersionService,
} from '@memberspot/frontend/shared/util/version';
import { provideEffectsManager } from '@ngneat/effects-ng';
import { ElfNgRouterStoreModule } from '@ngneat/elf-ng-router-store';
import { AngularQueryDevtools } from '@tanstack/angular-query-devtools-experimental';
import {
  provideAngularQuery,
  QueryClient,
} from '@tanstack/angular-query-experimental';
import { initializeFirestore } from 'firebase/firestore';
import { distinctUntilChanged, map, of } from 'rxjs';

import { VERSION } from '../../../../version';
import { environment } from '../environments/environment';
import { ignoredAdminErrors } from './admin-error-data';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HeroModule } from './hero.module';
import { SCHOOL_ID_PROVIDER } from './school-id.provider';
import { ImportLoaderAdmin } from './transloco-import-loader-admin';

const firestoreSettings: FirestoreSettings = {
  experimentalForceLongPolling: environment.firestoreLongPolling,
};

function loadAppConfig() {
  const adminService = inject(AdminConfigService);

  return () => adminService.loadAppConfig(environment.configFile);
}

const disableAnimations =
  !('animate' in document.documentElement) ||
  (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent));

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule.withConfig({ disableAnimations }),
    AppRoutingModule,
    FrontendAuthFeatureLoginModule,
    ToolsModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    AkitaNgRouterStoreModule,
    HeroModule,
    FrontendSharedUtilSentryModule.forRoot(environment.production, {
      dsn: 'https://4fd57f43ae3144bb96849127ea96ec8e@sentry.io/1895650',
      version: VERSION.version,
      envTag: environment.envTag,
      hideErrors: environment.hideErrors,
      ignoreErrors: ignoredAdminErrors,
    }),
    TranslocoRootModule.forRoot({
      production: environment.production,
      scope: 'admin',
      canReRender: true,
      defaultLoader: ImportLoaderAdmin,
    }),
    FrontendSharedUtilIntercomModule.forRoot({
      api_base: 'https://api-iam.intercom.io',
      app_id: 'k2sjzvsc',
      updateOnRouterChange: true,
      active: environment.production,
    }),
    VersionModule.setEnvironment(environment),
    // Kills SSR, has to be removed before SSR can be enabled
    ElfNgRouterStoreModule,
    AngularQueryDevtools,
  ],
  providers: [
    provideExperimentalZonelessChangeDetection(),
    provideFirebaseApp(() => {
      if (isPlatformBrowser(inject(PLATFORM_ID))) {
        return initializeApp(environment.firebase);
      }
    }),
    provideAuth(() => getAuth(inject(FirebaseApp))),
    // provideFirebaseApp(() => initializeApp(environment.firebase)),
    // provideAuth(() => getAuth(inject(FirebaseApp))),
    provideFunctions(() => getFunctions(undefined, 'europe-west1')),
    provideFirestore(() =>
      initializeFirestore(inject(FirebaseApp), firestoreSettings),
    ),
    provideStorage(() => getStorage()),
    provideAngularQuery(
      new QueryClient({
        defaultOptions: {
          queries: {
            gcTime: 1000 * 60 * 60,
            staleTime: environment.production ? 1000 * 60 * 15 : 1000 * 60 * 30,
          },
        },
      }),
    ),
    provideNativeDateAdapter(),
    provideFrontendSharedUiHotToastConfig(),
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: loadAppConfig,
    },
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: SCROLL_TOP_DISTANCE, useValue: 180 },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    { provide: IS_ADMIN, useValue: true },
    { provide: BACKEND_URL, useValue: environment.backend },
    // { provide: SETTINGS, useValue: firestoreSettings },
    { provide: BUCKETS, useValue: environment.buckets },
    {
      provide: DIGISTORE_WEBOOK_URL,
      useValue: environment.digistoreWebhookUrl,
    },
    { provide: COPECART_WEBOOK_URL, useValue: environment.copecartWebhookUrl },
    { provide: ZOOM_WEBOOK_URL, useValue: environment.zoomWebhookUrl },
    { provide: ELOPAGE_WEBHOOK_URL, useValue: environment.elopageWebhookUrl },
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: {
        disabled: true,
        animation: {
          enterDuration: 0,
          exitDuration: 0,
        },
      },
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        backdropClass: ['backdrop-blur-sm', 'cdk-overlay-dark-backdrop'],
      },
    },
    { provide: 'googleTagManagerId', useValue: environment.gtmId },
    { provide: PROD, useValue: environment.production },
    { provide: FILE_CDN, useValue: environment.fileCdn },
    { provide: CLIENT_URL, useValue: environment.clientUrl },
    { provide: STRIPE_API_KEY, useValue: environment.stripe.apiKey },
    {
      provide: HUBSPOT_CLIENT_ID,
      useValue: environment.hubspotClientId,
    },
    {
      provide: 'bitmovinAnalyticsToken',
      useValue: environment.bitmovinAnalyticsToken,
    },
    {
      provide: ZOHO_CLIENT_ID,
      useValue: environment.zohoClientId,
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: <MatTooltipDefaultOptions>{
        touchGestures: 'off',
      },
    },
    {
      provide: MAT_FAB_DEFAULT_OPTIONS,
      useValue: { color: 'void' },
    },
    SCHOOL_ID_PROVIDER,
    {
      provide: GOOGLE_LOGIN_PROVIDER,
      useExisting: RegisterService,
    },
    {
      provide: AZURE_AD_LOGIN_PROVIDER,
      useExisting: RegisterService,
    },
    {
      provide: APPLE_LOGIN_PROVIDER,
      useExisting: RegisterService,
    },
    {
      provide: APP_VERSION,
      useValue: VERSION.version,
    },
    {
      provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS,
      useValue: {
        panelClass: ['bg-dwhite', 'bottom-sheet-actions'],
        hasBackdrop: true,
        closeOnNavigation: true,
      },
    },
    SCOLLED_PROVIDER,
    provideEffectsManager(),
    {
      provide: CLIENT_DASHBOARD_USER_SERVICE_TOKEN,
      useValue: {
        select: () => of(undefined),
      },
    },
    {
      provide: FEATURE_TOGGLE_INJECTION_TOKEN,
      useValue: environment.featureToggles,
    },
    {
      provide: HUBSPOT_TOKEN,
      useValue: environment.hubspot,
    },
    CUSTOM_PROPERTIES_PROVIDER,
    {
      provide: CUSTOM_COLORS,
      useFactory: () =>
        inject(MemberAreaQuery)
          .selectActive()
          .pipe(map((ma) => ma?.theme?.customColors || [])),
    },
    {
      provide: SideMenuActiveStyleInjectionToken,
      useValue: of({}),
    },
    {
      provide: CUSTOM_VIEW,
      useFactory: () =>
        inject(MemberAreaQuery)
          .selectActive()
          .pipe(map((ma) => ma?.customView)),
    },
    {
      provide: IS_READ_ADMIN,
      useFactory: () =>
        inject(AuthQuery).selectReadOrSuperAdmin().pipe(distinctUntilChanged()),
    },
    {
      provide: IS_DEV_SUPPORT,
      useFactory: () =>
        inject(AuthQuery).selectDevSupport().pipe(distinctUntilChanged()),
    },
    {
      provide: INTERNAL_BETA,
      useFactory: () =>
        inject(SchoolsQuery)
          .selectActive()
          .pipe(
            map((s) => s?.internalBeta),
            distinctUntilChanged(),
          ),
    },
    {
      provide: EXTENDED_BETA,
      useFactory: () =>
        inject(SchoolsQuery)
          .selectActive()
          .pipe(
            map(
              (s) =>
                s?.extendedBeta ||
                (s?.aiSettings?.hasAiFunctionsActivated &&
                  s.extendedBeta !== false),
            ),
            distinctUntilChanged(),
          ),
    },
    provideUserTracking({
      token: environment.mixpanelToken,
      debug: environment.envTag === 'development',
      environment: environment.envTag,
    }),
    {
      provide: FONT_CDN,
      useValue: environment.fontCdn,
    },
    {
      provide: THUMBNAIL_SERVICE_URL,
      useValue: environment.thumbnailServiceUrl,
    },
    VersionService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
