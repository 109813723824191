<ng-container *transloco="let t">
  <div
    class="flex items-center justify-end overflow-hidden"
    [class.lg:justify-between]="school$ | async"
  >
    @if (!isHandset && school$ | async) {
      <div class="mr-3">
        <mspot-search-input
          (search)="search($event)"
          width="450px"
        ></mspot-search-input>
      </div>
    }
    <div class="flex w-full items-center justify-end text-gray-500">
      @if (customer$ | async; as customer) {
        @if (customer.trailing) {
          <button
            class="btn ml-3 mr-3 hidden rounded-3xl text-gray-700 md:inline-block lg:ml-5"
            (click)="goToPlans(customer.id)"
            matTooltip="{{
              customer.remainingTrailDays >= 0
                ? t('admin.headerMenu.trialRemaining', {
                    days: customer.remainingTrailDays,
                  })
                : t('admin.headerMenu.trialExpired')
            }}"
          >
            {{
              customer.remainingTrailDays >= 0
                ? t('admin.headerMenu.trialDaysLeft', {
                    days: customer.remainingTrailDays,
                  })
                : t('admin.headerMenu.trialExpired')
            }}
          </button>

          <button
            (click)="goToPlans(customer.id)"
            class="btn mr-3 hidden rounded-3xl text-gray-700 sm:inline-block md:hidden"
          >
            {{
              customer.remainingTrailDays >= 0
                ? t('admin.headerMenu.trialDays', {
                    days: customer.remainingTrailDays,
                  })
                : '-'
            }}
          </button>
        }
      }

      @if (onboardingActive() && school$ | async) {
        <div class="mr-1">
          <mspot-admin-onboarding-global-info
            [version]="GUIDE_VERSION.GUIDE_2024_08_19"
          ></mspot-admin-onboarding-global-info>
        </div>
      }

      @if (updateAvailable$ | async) {
        <button
          class="btn--icon-only btn-gray--icon-light mr-1"
          (click)="reload()"
          matTooltip="{{ t('layout.updateAvailable') }}"
        >
          <ng-icon name="hero-arrow-path" size="1.375rem"></ng-icon>
        </button>
      }

      @if (appNotInstalled$ | async) {
        <button
          class="btn--icon-only btn-gray--icon-light"
          (click)="installApp()"
          [ngClass]="schoolId ? 'mr-1' : 'mr-3'"
          matTooltip="{{ t('layout.installApp') }}"
        >
          <ng-icon
            ngProjectAs="mat-icon"
            name="custom-install-app"
            size="1.375rem"
          ></ng-icon>
        </button>
      }

      @if (schoolId) {
        <button
          class="btn--icon-only btn-gray--icon-light mr-1"
          (click)="goToAha()"
          matTooltip="{{ t('admin.headerMenu.feedback') }}"
        >
          <ng-icon
            ngProjectAs="mat-icon"
            name="hero-light-bulb"
            size="1.375rem"
          ></ng-icon>
        </button>
      }

      @if (schoolId) {
        <button
          class="btn--icon-only btn-gray--icon-light mr-3"
          (click)="goToMemberArea()"
          matTooltip="{{ t('admin.headerMenu.livePreview') }}"
        >
          <ng-icon name="hero-eye" size="1.375rem"></ng-icon>
        </button>
      }

      @if (authState$ | async; as user) {
        <button class="shrink-0" [matMenuTriggerFor]="profile">
          <img
            ngProjectAs="mat-icon"
            [src]="user?.photoURL || defaultProfileImg"
            class="header-toolbar__profile-pic"
            mspotProfileImgLoadErr
          />
        </button>
      }

      @if (schoolId && isHandset) {
        <button
          class="-mr-0.5 ml-5 flex items-center"
          (click)="toggleNav.emit()"
        >
          <ng-icon name="hero-bars-3" class="text-2xl"></ng-icon>
        </button>
      }
    </div>
  </div>

  <mat-menu #profile="matMenu">
    <a mat-menu-item routerLink="/profile" class="flex items-center">
      <ng-icon
        ngProjectAs="mat-icon"
        name="hero-identification"
        class=""
      ></ng-icon>
      {{ t('admin.headerMenu.profile') }}
    </a>

    <!-- <button mat-menu-item (click)="changeDarkMode(!darkMode)" class="flex items-center">
      <ng-icon ngProjectAs="mat-icon" name="hero-sun" class="text-xl text-gray-400 "></ng-icon>
      {{ darkMode ? 'Light Mode' : 'Dark Mode' }}
    </button> -->

    @if (schoolId) {
      <a
        mat-menu-item
        [routerLink]="'/' + schoolId + '/settings/billing/account'"
        class="flex items-center"
      >
        <ng-icon
          ngProjectAs="mat-icon"
          name="hero-banknotes"
          class=""
        ></ng-icon>
        {{ t('admin.account.yourAccount.invoices') }}
      </a>
    }

    <a
      mat-menu-item
      cypress="appheader__settingsmenu__changeSchool"
      routerLink="/schools"
      [queryParams]="{ manual: true }"
      class="flex items-center"
    >
      <ng-icon
        ngProjectAs="mat-icon"
        name="hero-arrows-right-left"
        class=""
      ></ng-icon>
      {{ t('admin.memberArea.change') }}
    </a>

    <button (click)="changeDarkMode(!darkMode)" mat-menu-item>
      <ng-icon
        ngProjectAs="mat-icon"
        [name]="darkMode ? 'hero-sun' : 'hero-moon'"
      ></ng-icon>
      {{ darkMode ? 'Light Mode' : 'Dark Mode' }}
    </button>

    <button mat-menu-item [matMenuTriggerFor]="languages">
      <ng-icon
        ngProjectAs="mat-icon"
        name="hero-globe-europe-africa"
        class=""
      ></ng-icon>
      {{ t('profile.language') }}
    </button>

    <button mat-menu-item mspotSignOut class="flex items-center">
      <ng-icon
        ngProjectAs="mat-icon"
        name="hero-arrow-left-on-rectangle"
        class=""
      ></ng-icon>
      {{ t('auth.logout') }}
    </button>
  </mat-menu>

  <mat-menu #languages="matMenu">
    <button
      (click)="changeLanguage(Language.Eng)"
      mat-menu-item
      class="flex gap-3"
    >
      <img
        ngProjectAs="mat-icon"
        src="assets/flags/gb.svg"
        title="English"
        class="h-7 w-7"
      />
      {{ t('languages.en') }}
    </button>
    <button
      (click)="changeLanguage(Language.De)"
      mat-menu-item
      class="flex gap-3"
    >
      <img
        ngProjectAs="mat-icon"
        src="assets/flags/de.svg"
        class="h-7 w-7"
        title="Deutsch"
      />
      {{ t('languages.de') }}
    </button>
  </mat-menu>
</ng-container>
