import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { SchoolAdminPermissionQuery } from '@memberspot/admin/shared/data-access/school-admin-permission';
import { FrontendSearchUiSearchInputModule } from '@memberspot/frontend/search/ui/search-input';
import { ContentLoaderComponent } from '@memberspot/frontend/shared/ui/content-loader';
import { defaultLogo } from '@memberspot/models';
import { School } from '@memberspot/shared/model/school';

import { MenuItemsService } from '../../menu-items/menu-items-service';
import { SchoolLogoComponent } from '../school-logo/school-logo.component';
import { MenuItemComponent } from './menu-item/menu-item.component';

@Component({
  selector: 'app-sidebar-new',
  templateUrl: './sidebar-new.component.html',
  styleUrls: ['./sidebar-new.component.scss'],
  host: {
    class: 'h-full',
  },
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SchoolLogoComponent,
    MenuItemComponent,
    ContentLoaderComponent,
    CommonModule,
    FrontendSearchUiSearchInputModule,
  ],
})
export class AppSidebarNewComponent {
  @Output() sideBarClicked = new EventEmitter();
  @Output() search = new EventEmitter<string>();

  @Input() school: School;
  @Input() schools: School[];
  @Input() isLg?: boolean;
  @Input() isHandset?: boolean;
  @Input() scrolled = false;
  @Input() darkSidebar?: boolean;

  status = true;

  get defaultLogo() {
    return defaultLogo;
  }

  get logo() {
    return this.school?.logoUrl || this.defaultLogo;
  }

  get borderColor() {
    if (this.darkSidebar) {
      return 'border-gray-700';
    } else if (this.scrolled) {
      return 'border-gray-300';
    }

    return 'border-gray-50';
  }

  loading$ = inject(SchoolAdminPermissionQuery).selectLoading();

  constructor(public menuItemsService: MenuItemsService) {}

  clicked() {
    this.sideBarClicked.emit();
  }
}
