import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Auth, User, user } from '@angular/fire/auth';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { AhaService } from '@memberspot/admin/shared/data-access/aha';
import { AuthService } from '@memberspot/admin/shared/data-access/auth';
import { CustomersQuery } from '@memberspot/admin/shared/data-access/customers';
import { SchoolsQuery } from '@memberspot/admin/shared/data-access/schools';
import {
  AdminOnboardingGlobalInfoComponent,
  AdminOnboardingService,
} from '@memberspot/admin/shared/feature/admin-onboarding';
import { PreviewService } from '@memberspot/admin/shared/feature/school-url';
import { SignOutDirective } from '@memberspot/frontend/auth/service';
import {
  FrontendSearchUiSearchInputModule,
  SearchInputMobileComponent,
} from '@memberspot/frontend/search/ui/search-input';
import {
  PwaInstallService,
  SoftwareUpdateService,
} from '@memberspot/frontend/shared/feature/pwa';
import { Customer, SubscriptionStatus } from '@memberspot/models';
import { GUIDE_VERSION } from '@memberspot/shared/model/admin-onboarding';
import { School } from '@memberspot/shared/model/school';
import { Language } from '@memberspot/shared/model/types';
import { exampleHeaderProfileSlotUrl } from '@memberspot/shared/util/constants';
import { NgIconComponent } from '@ng-icons/core';
import { combineLatest, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatTooltipModule,
    NgIconComponent,
    MatMenuModule,
    FrontendSearchUiSearchInputModule,
    RouterLink,
    TranslocoModule,
    SignOutDirective,
    AdminOnboardingGlobalInfoComponent,
  ],
  providers: [AdminOnboardingService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderComponent implements OnInit {
  @Input() isHandset: boolean;
  @Input() darkMode: boolean;
  @Input() schoolId: string;

  @Output() toggleNav = new EventEmitter();

  @ViewChild(SearchInputMobileComponent)
  searchMobile: SearchInputMobileComponent;

  protected authState$ = user(this._auth);

  updateAvailable$ = inject(SoftwareUpdateService).updateAvailable$;

  appNotInstalled$ = this._pwaInstallService.appNotInstalled$;
  customer$: Observable<Customer>;
  school$: Observable<School>;
  authSchool$: Observable<[User, School]>;

  defaultProfileImg = exampleHeaderProfileSlotUrl;
  SubscriptionStatus = SubscriptionStatus;
  Language = Language;

  private _adminOnboardingService: AdminOnboardingService = inject(
    AdminOnboardingService,
  );

  public onboardingActive = this._adminOnboardingService.active;

  protected readonly GUIDE_VERSION = GUIDE_VERSION;

  constructor(
    private _auth: Auth,
    private _pwaInstallService: PwaInstallService,
    private _router: Router,
    private _customerQuery: CustomersQuery,
    private _schoolsQuery: SchoolsQuery,
    private _previewService: PreviewService,
    private _ahaService: AhaService,
    private _authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.customer$ = this._customerQuery
      .selectActive()
      .pipe(shareReplay({ refCount: true }));
    this.school$ = this._schoolsQuery.selectActive();

    this.authSchool$ = combineLatest([
      this.authState$,
      this._schoolsQuery.selectActive(),
    ]);
  }

  get schoolUrl() {
    return;
  }

  reload() {
    window.location.reload();
  }

  installApp() {
    this._pwaInstallService.install();
  }

  goToMemberArea() {
    this._previewService.goToLiveVersion();
  }

  goToAha() {
    this._ahaService.goToBoardNew();
  }

  goToPlans(schoolId: string) {
    this._router.navigate([schoolId, 'settings', 'billing', 'pricing'], {
      queryParams: {
        menu: true,
      },
    });
  }

  changeDarkMode(darkMode: boolean) {
    void this._authService.updateProfile({
      settings: {
        darkMode,
      },
    });
  }

  changeLanguage(langCode: Language) {
    return this._authService.updateUserLanguage(langCode).subscribe();
  }

  search(searchTerm: string) {
    if (!searchTerm) {
      return;
    }

    this._router.navigate(['/', this._schoolsQuery.getActiveId(), 'search'], {
      queryParams: { q: searchTerm },
    });
  }
}
